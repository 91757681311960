@import './_variables.scss';

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../fonts/open-sans/open-sans-v20-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/open-sans/open-sans-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('../fonts/open-sans/open-sans-v20-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/open-sans/open-sans-v20-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('../fonts/open-sans/open-sans-v20-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/open-sans/open-sans-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior: none;
}

.customScrollBar {
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #eee;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
  }
}

button {
  border-radius: 3px;
  background-color: $primaryColor;
  text-align: center;
  border: none;
  padding: 8px 24px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;
  &.shadow {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }
  &.outline {
    background-color: transparent;
    border: 1px solid $primaryColor;
    color: $primaryColor;
    padding: 9px 14px;
  }
  &[disabled] {
    background-color: #dadada;
    color: #666666;
    cursor: not-allowed;
    box-shadow: none;
  }
}

.adminContent {
  position: absolute;
  overflow-y: auto;
  vertical-align: top;
  display: inline-block;
  background-color: rgb(234, 239, 241);
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100vh;
  padding: 30px 25px 60px;
  @media all and (max-width: 768px) {
    padding: 25px 15px 60px;
  }
  &.showUI {
    width: 100%;
    top: 54px;
    height: calc(100vh - 54px);
  }
  input[type='text'] + input[type='text'] {
    margin-top: -1px;
  }
  .card {
    padding: 32px 16px 20px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
  }
  .search-view {
    margin-bottom: 30px;
    padding: 16px;
    background-color: #ffffff;
    .searchField {
      margin-bottom: 4px;
      width: calc(33.3% - 12px);
      display: inline-block;
      vertical-align: top;
      &:not(:nth-child(3n + 1)) {
        margin-left: 18px;
      }
      label {
        margin: 2px 0px;
        font-weight: 500;
        color: #666666;
      }
      & > input[type='text'] {
        display: inline-block;
        height: 36px;
        width: 100%;
      }
    }
    .searchButton {
      padding: 9px 0px 1px;
      text-align: right;
    }
    input[type='checkbox'] {
      display: block;
    }
    .Select {
      width: 100%;
    }
    input[type='button'] {
      width: auto;
      height: 35px;
    }
  }
  .Select-menu-outer {
    z-index: 3;
  }
  .ql-toolbar {
    border-radius: 5px 5px 0px 0px;
  }
  .ql-container {
    border-radius: 0px 0px 5px 5px;
    &.disabled {
      border-radius: 5px;
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }
  .pagination {
    width: calc(100% - 115px);
    margin: 12px 0px 0px;
    li {
      a,
      a:focus {
        color: $primaryColor;
        outline: none;
      }
    }
    .disabled {
      a,
      a:focus {
        color: #777777;
      }
    }
    .active,
    .active:hover {
      a,
      a:focus {
        color: #ffffff;
        background-color: $primaryColor;
        border-color: $primaryColor;
      }
    }
  }
  .Select {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    input {
      border: none;
      box-shadow: none;
    }
    .Select-control {
      .Select-multi-value-wrapper {
        white-space: normal;
      }
    }
  }
  .Select.is-disabled .Select-control,
  .form-control[disabled],
  .form-control[readonly] {
    cursor: not-allowed;
    background-color: #f5f5f5;
  }
}

*:hover {
  & > .tooltip {
    display: block;
  }
}

.tooltip {
  display: none;
  position: absolute;
  transform: translate(-50%, 0%);
  left: 50%;
  bottom: 45px;
  border-radius: 10px;
  padding: 12px 12px 16px 16px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #666666;
  text-transform: initial;
  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 10px);
    bottom: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffff;
  }
}

@media all and (max-width: 768px) {
  .adminContent {
    width: 100%;
    margin-left: 0px;
    .search-view {
      .searchField {
        width: 100%;
        &:not(:nth-child(3n + 1)) {
          margin-left: 0px;
        }
      }
      .searchButton {
        padding: 16px 0px 8px;
      }
    }
  }
}

.infoLabel {
  position: absolute;
  display: inline-block;
  background-color: #666;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 4px;
  border-radius: 10px;
  width: 80px;
  text-align: center;
}

.selectInput {
  outline: none;
  .selectInput__control {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    min-height: 36px;
    &.selectInput__control--is-disabled {
      cursor: not-allowed;
      background-color: #f5f5f5;
    }
    .selectInput__indicator {
      padding: 7px 8px;
    }
    .selectInput__value-container {
      .selectInput__single-value {
        &.selectInput__single-value--is-disabled {
          color: #333333;
        }
      }
    }
  }
  .selectInput__control--is-focused,
  .selectInput__control--is-focused:hover {
    border-color: #cccccc;
  }
  /*.selectInput__indicator-separator {
    display: none;
  }*/
}

.errorMessage {
  color: #ce4844;
  display: block;
}

.userExportButton {
  padding: 9px 2px;
  text-align: right;
}
