@import '../../variables.scss';

.wysiwygInput {
  width: 100%;
  padding: 6px 0px;
  .wysiwygWrapper {
    border-radius: 3px;
    box-shadow: none;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    overflow: hidden;
    .wysiwygToolbar {
      margin: 0px;
      padding: 12px 10px 8px;
      box-shadow: none;
      .rdw-option-wrapper {
        border: none;
        box-shadow: none;
      }
    }
    .wysiwygEditor {
      color: #333333;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      .DraftEditor-root {
        .public-DraftEditorPlaceholder-inner {
          padding: 16px 20px;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #bbbbbb;
        }
        .DraftEditor-editorContainer {
          height: 350px;
          overflow-y: auto;
          .public-DraftEditor-content {
            padding: 16px 20px;
          }
        }
      }
      .public-DraftStyleDefault-block {
        margin: 0px 0px 12px;
      }
    }
    .rdw-link-modal {
      height: auto;
      label {
        color: #666666;
        font-weight: normal;
      }
      .rdw-link-modal-buttonsection {
        margin: 10px 0px 0px;
        text-align: right;
        button {
          display: inline-block;
          padding: 3px 14px;
          width: auto;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
    .rdw-image-modal {
      .rdw-image-modal-url-section {
        .rdw-image-modal-url-input {
          width: 100%;
        }
      }
      .rdw-image-modal-btn-section {
        margin: 10px 0px 0px;
        text-align: right;
        button {
          display: inline-block;
          padding: 3px 14px;
          width: auto;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
