@import '../_variables.scss';

.sidebarView {
  position: fixed;
  left: 0px;
  top: 53px;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 80;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );
  &:not(.open) {
    transform: translate(-100%, 0%);
  }
  .sidebarContainer {
    width: 260px;
  }
  .logo {
    width: 100%;
    height: 54px;
    background-color: #f5f5f5;
    background-image: url(../../../public/logo.png);
    background-position: 16px center;
    background-size: 100px;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    color: #666666;
    font-weight: 500;
    font-size: 16px;
    padding-left: 98px;
    padding-bottom: 10px;
  }
  .menuList {
    width: 100%;
    height: calc(100vh - 54px);
    background-color: #f5f5f5;
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .header {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      padding: 5px 15px 5px 10px;
      color: #333333;
    }
    .divider {
      margin-top: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
    .listItem {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      padding: 5px 16px;
      color: rgba(0, 0, 0, 0.7);
      cursor: pointer;
      background-color: transparent;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      &.selected {
        color: $primaryColor;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .sidebarView {
    width: 100%;
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
